import React, { useState, useEffect } from "react";
import { Card, Row, Col, Popover, OverlayTrigger, Button, Image } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { isIE } from "react-device-detect";
import { FaChevronCircleRight } from "react-icons/fa";

import Link from "../widgets/ScrollToTopLink";
import InfoIcon from "../../images/icons/info@3x.png";


export default function CarCardCarrousel (props) {
    const { fullHeader, title, trans, onSelectedPasos, info } = props;
    //console.log("Tengo lo siguiente en el props_: ", props)

    return (
        <Card 
            className="car-card shadow-sm text-primary-colorh-100" 
            onClick={() => onSelectedPasos(info, "Paso2")}
            >
            <Header full={fullHeader} {...props} />
            <Card.Body>
                <Card.Title>
                    <h6 className="texto-carCardCarrousel-titulo">
                        {title}{" "}
                    </h6>
                </Card.Title>
            </Card.Body>
            <Card.Footer style={{backgroundColor: '#343a40'}}>
                <Row>
                    <span className="text-right text-secundario" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <h6 style={{marginBottom: 0, fontWeight: '400', fontSize: 12, marginRight: 5, color: "white"}}> Ver más de tu auto nuevo</h6>
                        <div style={{color: "white"}}> <FaChevronCircleRight size={12}/> </div>
                    </span>
                </Row>
            </Card.Footer>
        </Card>
    )

}

function Header({image, year, model, brand, price}){
    const [isLoaded, setIsLoaded] = useState(false);

    const webp300 = image ? image.replace(".png", "@300w.webp") : "";
    const webp600 = image ? image.replace(".png", "@600w.webp") : "";
    const png600 = image ? image.replace(".png", "@600w.png") : "";
    const png300 = image ? image.replace(".png", "@300w.png") : "";
    
    return(
        <Card.Header style={{ padding: 0, height: 150, overflow: 'hidden'}}>
            <picture>
                <source srcSet={`${webp300} 1.0x, ${webp600} 2.0x`} type="image/webp"/>
                <source srcSet={`${png300} 1.0x, ${png600} 2.0x`} type="image/png" />
                <img
                    loading="lazy"
                    src={image}
                    alt={ brand + ` ` + model + ` ` + year + ` $` + price + ` - Agencia Chevrolet - Crédito de auto`}
                    style={{width: '100%'}}
                />
            </picture>
        </Card.Header>
    )

}